import {AvatarFallback, AvatarImage} from '@radix-ui/react-avatar'
import {AvatarRoot} from '@components/ui/Avatar/style.ts'
import {getInitials} from '@utilities/helpers.ts'

interface AvatarProps {
    name: string
    imageUrl: string
}

const Avatar = ({name, imageUrl}: AvatarProps) => (
    <AvatarRoot>
        <AvatarImage src={imageUrl} alt={name} />
        <AvatarFallback delayMs={600}>{getInitials(name)}</AvatarFallback>
    </AvatarRoot>
)

export default Avatar

Avatar.displayName = 'Avatar'
