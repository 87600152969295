import {StyledButton} from './style'
import {ButtonHTMLAttributes, forwardRef, ReactNode} from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode
    className?: string
    fullWidth?: boolean
    iconOnly?: boolean
    size?: 'sm' | 'md' | 'lg' | 'xl'
    variant?: 'primary' | 'primaryDanger' | 'secondary' | 'ghost' | 'ghostDanger' | 'linkColor' | 'tertiary'
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            className,
            disabled,
            fullWidth,
            iconOnly,
            size = 'md',
            type = 'button',
            variant = 'primary',
            ...rest
        },
        ref
    ) => (
        <StyledButton
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            iconOnly={iconOnly}
            ref={ref}
            size={size}
            type={type}
            variant={variant}
            {...rest}
        >
            {children}
        </StyledButton>
    )
)

export default Button

Button.displayName = 'Button'
