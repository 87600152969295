import {Outlet} from 'react-router-dom'
import {StyledMain, StyledPrivateLayout} from './styles'
import React from 'react'
import Header, {MenuItem} from '@components/commons/Header'
import {Footer} from '@components/commons/Footer/Footer.tsx'

//TODO: items array should be removed and replaced by paths rendered based on the user permissions
const ITEMS: MenuItem[] = []
type PrivateLayoutProps = {
    withHeader?: boolean
}
const PrivateLayout: React.FC<PrivateLayoutProps> = ({withHeader}) => {
    return (
        <>
            <StyledPrivateLayout>
                {withHeader && <Header items={ITEMS} />}
                <StyledMain>
                    <Outlet />
                </StyledMain>
                <Footer />
                {/*   <SupportButton emailAddress={'francesco.monti@kromin.it'} emailSubject={'CO-detector-form-support'} />*/}
            </StyledPrivateLayout>
        </>
    )
}

export default PrivateLayout
