import {RouterProvider} from 'react-router-dom'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import AuthProvider from '@context/AuthProvider.tsx'
import {getAppTheme, GlobalStyles} from '@/theme'
import TagManager from 'react-gtm-module'
import router from './router.tsx'
import {Toaster} from 'react-hot-toast'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {envVars} from '@/envVars.ts'

const tagManagerProdArgs = {
    gtmId: 'GTM-WXPQBM33'
}
const tagManagerStageArgs = {
    gtmId: 'GTM-MFV2J3PB'
}

function App() {
    const themeName = 'light' //get it from the app reducer
    const {
        i18n,
        i18n: {language}
    } = useTranslation()

    useEffect(() => {
        document.body.dir = i18n.dir()
    }, [language])

    useEffect(() => {
        TagManager.initialize(
            envVars.VITE_API_ENDPOINT === 'https://api-mediabox.k-stage.dev/api'
                ? tagManagerStageArgs
                : tagManagerProdArgs
        )
    }, [])

    return (
        <ThemeProvider theme={getAppTheme({name: themeName})}>
            <StyleSheetManager>
                <AuthProvider>
                    <Toaster />
                    <GlobalStyles />
                    <RouterProvider router={router} />
                </AuthProvider>
            </StyleSheetManager>
        </ThemeProvider>
    )
}

export default App
