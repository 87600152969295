import styled, {css} from 'styled-components'

export const StyledSmokeFormWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 500px 400px;
        align-items: start;
        justify-content: space-between;
        ${mediaQueries.m} {
            grid-template-columns: 1fr;
            gap: ${spacing * 8}px;
        }
    `}
`
