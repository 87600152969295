import {Outlet} from 'react-router-dom'
import React from 'react'
import {StyledMain, StyledPrivateLayout} from '@layouts/private-layout/styles.ts'
import Header, {MenuItem} from '@components/commons/Header'
import {Footer} from '@components/commons/Footer/Footer.tsx'

//TODO: items array should be removed and replaced by paths rendered based on the user permissions
const ITEMS: MenuItem[] = []

const PublicLayout: React.FC = () => {
    return (
        <>
            <StyledPrivateLayout>
                <Header items={ITEMS} />
                <StyledMain>
                    <Outlet />
                </StyledMain>
                {/*<SupportButton emailAddress={'francesco.monti@kromin.it'} emailSubject={'CO-detector-form-support'} />*/}
                <Footer />
            </StyledPrivateLayout>
        </>
    )
}

export default PublicLayout
