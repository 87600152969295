import styled, {css} from 'styled-components'

export const RequestDetailsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        align-items: start;
        justify-content: center;
        grid-template-rows: 1fr;
        justify-items: center;
        margin-bottom: ${spacing * 20}px;
    `}
`

export const RequestDetailsBox = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 480px;

        & > img {
            margin: 0 auto ${spacing * 6}px;
        }

        & > h4 {
            ${typography.displaySm};
            font-weight: 700;
            color: ${palette.neutral['900']};
        }

        & > p {
            ${typography.textMd};
            margin: ${spacing * 2}px 0 ${spacing * 6}px 0;
            color: ${palette.neutral['900']};
        }
    `}
`

export const RequestDetailCard = styled.div`
    ${({theme: {palette, spacing}}) => css`
        border-radius: 12px;
        overflow: hidden;
        border: 1px solid ${palette.neutral['100']};
        margin: ${spacing * 10}px 0;
        max-width: 500px;
        width: 100%;
    `}
`

export const RequestDetailCardHeader = styled.div<{expanded: boolean}>`
    ${({theme: {typography, palette, spacing}, expanded}) => css`
        border-bottom: 1px solid ${palette.neutral['200']};
        padding: ${spacing * 3}px ${spacing * 4}px;
        text-align: start;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & p {
            ${typography.textLg};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
        & svg {
            transform: ${expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
        }
    `}
`

export const RequestDetailCardBody = styled.div<{expanded: boolean}>`
    ${({theme: {typography, palette, spacing}, expanded}) => css`
        background: ${palette.neutral['100']};
        text-align: start;
        display: grid;
        gap: ${spacing * 4}px;
        padding: ${expanded ? spacing * 4 : 0}px;
        border-bottom: 1px solid #ccc;
        height: ${expanded ? '100%' : 0}px;
        &:last-child {
            border-bottom: unset;
        }

        & h4 {
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
    `}
`

export const ShippingInfoBox = styled.div`
    ${({theme: {typography, spacing, palette}}) => css`
        display: grid;
        width: 100%;
        grid-template-columns: auto 1fr;
        gap: ${spacing * 2}px;
        background: ${palette.neutral['100']};
        align-items: start;
        color: ${palette.neutral['600']};

        & > div {
            text-align: start;
            display: grid;
            gap: ${spacing}px;

            & > small {
                ${typography.textSm};
                color: ${palette.neutral['600']};
            }

            & > p {
                ${typography.textMd};
                font-weight: 500;
                color: ${palette.neutral['900']};
            }

            & > span {
                ${typography.textSm};
                color: ${palette.neutral['600']};
            }
        }
    `}
`

export const AddressErrorCta = styled.p`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textSm};
        font-weight: 400;
        color: ${palette.neutral['800']};
        display: flex;
        gap: ${spacing}px;
        & a {
            cursor: pointer;
            text-decoration: underline;
            font-weight: 500;
        }
    `}
`
