import styled, {css} from 'styled-components'

export const MapWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        display: flex;
        margin-top: ${spacing * 2}px;
        flex-direction: column;
        background: ${palette.neutral.white};
        border: 1px solid ${palette.neutral['300']};
        border-radius: 8px;
        overflow: hidden;
        p {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral['700']};
            padding: ${spacing * 2}px;
            border-bottom: 1px solid ${palette.neutral['300']};
        }
    `}
`
