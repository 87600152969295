import {Navigate, Outlet, useNavigate} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {useAuth} from '@context/AuthProvider.tsx'
import {useEffect, useState} from 'react'
import {LoadingScreen} from '@components/commons/LoadingScreen/LoadingScreen.tsx'

const PrivateRoute = () => {
    const {accessToken, user} = useAuth()
    const navigate = useNavigate()
    const [guardCondition, setGuardCondition] = useState(false)

    useEffect(() => {
        /* if (user) {
            if (user.active_listings < 1) {
                navigate(routes.INACTIVE.path)
            } else if (
                user.total_sensors_requested >= 1 ||
                (user.total_sensors_requested >= user.active_listings && user.active_listings > 0)
            ) {
                navigate(routes.REQUEST_DETAILS.path)
            } else {
                navigate(routes.HOME.path)
            }
            setGuardCondition(true)
        }*/
        if (user) {
            if (user.active_listings < 1) {
                navigate(routes.INACTIVE.path)
            } else if (
                user.total_sensors_requested >= 1 ||
                (user.total_sensors_requested >= user.active_listings && user.active_listings > 0)
            ) {
                navigate(routes.REQUEST_DETAILS.path)
            } else {
                navigate(routes.REQUEST_DETAILS.path)
            }
            setGuardCondition(true)
        }
    }, [user])

    return (
        <>
            {!accessToken && <Navigate to={routes.LOGIN.path} />}
            {user && guardCondition ? <Outlet /> : <LoadingScreen />}
        </>
    )
}

export default PrivateRoute
