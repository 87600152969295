import {useTranslation} from 'react-i18next'
import {IconContainer} from '@components/ui/IconContainer'
import {SearchLgIcon} from 'src/components/ui/icon'
import Button from '@components/ui/button/Button.tsx'
import {StyledContainer, StyledNotFoundWrapper} from '@pages/notFound/style.ts'
import {useNavigate} from 'react-router-dom'
import {routes} from '@utilities/constants'

export const NotFound = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()

    return (
        <StyledContainer fullHeight={true}>
            <StyledNotFoundWrapper>
                <IconContainer>
                    <SearchLgIcon />
                </IconContainer>
                <h1>{t('notFound:title')}</h1>
                <p>{t('notFound:subtitle')}</p>
                <Button variant={'primary'} size={'lg'} onClick={() => navigate(routes.HOME.path)}>
                    {t('notFound:cta')}
                </Button>
            </StyledNotFoundWrapper>
        </StyledContainer>
    )
}

NotFound.displayName = 'NotFound'
