import * as pages from '@/pages'

export const routes = {
    LOGIN: {
        path: '/login',
        element: <pages.Login />
    },
    COOKIE_POLICY: {
        path: '/cookie-policy',
        element: <pages.CookiePolicy />
    },
    SSO_REDIRECT: {
        path: '/redirect',
        element: <pages.SsoRedirect />
    },
    HOME: {
        path: '/',
        element: <pages.Home />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:home'
        }
    },
    HOME_EXTENDED: {
        path: '/home',
        element: <pages.Home />,
        handle: {
            path: '/home',
            paramName: '',
            name: 'routeNames:home'
        }
    },
    REQUEST_DETAILS: {
        path: '/request-details',
        element: <pages.RequestDetails />
    },
    INACTIVE: {
        path: '/inactive',
        element: <pages.Inactive />
    },
    NOT_FOUND: {
        path: '*',
        element: <pages.NotFound />
    }
}
