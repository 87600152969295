import styled, {css} from 'styled-components'

export const StyledModalToggle = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        cursor: pointer;
        border-radius: 4px;
        background: ${palette.neutral.white};
    `
)

export const StyledLangModal = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 6}px;

        & h3 {
            ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral['900']};
        }

        & p {
            ${typography.textSm};
            margin-top: ${spacing}px;
        }
    `
)

export const StyledContent = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
    `
)

export const StyledOptionsContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 8}px;
    `
)

type LangOptionProps = {
    isSelected: boolean
}
export const LangOption = styled.div<LangOptionProps>`
    ${({isSelected, theme: {spacing, typography, palette}}) => css`
        cursor: pointer;
        border: ${isSelected ? `1px solid ${palette.primary['300']}` : `1px solid ${palette.neutral['100']}`};
        border-radius: 4px;
        box-sizing: border-box;
        padding: ${spacing}px;
        transition: 300ms ease-in-out;
        &:hover {
            background: ${palette.neutral['50']};
            border: ${isSelected ? `1px solid ${palette.primary['300']}` : `1px solid ${palette.neutral['100']}`};
        }
        p {
            ${typography.textXs};
            font-weight: 500;
            color: ${palette.neutral['500']};
        }
    `}
`
