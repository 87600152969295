import {z} from 'zod'
import {ValidationSchema} from './ManualAddressFormModel'

export enum AddressType {
    route = 'route',
    sublocality_level_1 = 'sublocality_level_1',
    locality = 'locality',
    administrative_area_level_1 = 'administrative_area_level_1',
    postal_code = 'postal_code',
    street_number = 'street_number',
    country = 'country'
}

export type SantizedFormData = Omit<ValidationSchema, 'address_line_2' | 'address_line_3' | 'address_line_4'> & {
    address_line_optional?: string
}

export const ParsedManualAddress = z.object({
    formatted_address: z.string(),
    address: z
        .object({
            long_name: z.string(),
            short_name: z.string(),
            types: z.nativeEnum(AddressType).array()
        })
        .array()
})
export type ParsedManualAddress = z.infer<typeof ParsedManualAddress>

export const Country = z.object({
    label: z.unknown().optional(),
    value: z.string(),
    code: z.string().optional()
})
export type Country = z.infer<typeof Country>
