import React, {useState} from 'react'
import Button from '@components/ui/button/Button.tsx'
import Container from '@components/ui/Container'
import {LoginBox, LoginWrapper} from '@pages/login/style.ts'
import {Trans, useTranslation} from 'react-i18next'
import Logo from '@assets/images/logo_icon.svg'
import {useTheme} from 'styled-components'
import Spinner from '@components/ui/Spinner'
import {AirbnbIcon} from 'src/components/ui/icon'
import {envVars} from '@/envVars.ts'
import {Helmet} from 'react-helmet'
import {ServiceSuspendedBanner} from '@components/commons/service-suspended-banner/ServiceSuspendedBanner.tsx'

export const Login: React.FC = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const [isLoading, setIsLoading] = useState(false)
    const airbnbLoginHandler = () => {
        setIsLoading(true)
        window.location.href = `https://www.airbnb.com/oauth2/auth?client_id=${envVars.VITE_AIRBNB_APP_ID}&redirect_uri=${envVars.VITE_AIRBNB_DATA_REDIRECT_URI}&scope=${envVars.VITE_AIRBNB_DATA_SCOPE}&state`
    }

    return (
        <Container>
            <Helmet title={String(t('login:page_title'))} />
            <LoginWrapper>
                <LoginBox>
                    <img src={Logo} alt={'logo'} width={64} />
                    <h4>{t('login:title')}</h4>
                    <p>{t('login:subtitle')}</p>
                    <Button
                        variant={'primary'}
                        id={'airbnbLogin'}
                        size={'md'}
                        onClick={airbnbLoginHandler}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <Spinner size={18} color={palette.neutral.white} />
                        ) : (
                            <>
                                <AirbnbIcon />
                                {t('login:cta')}
                            </>
                        )}
                    </Button>
                    <ServiceSuspendedBanner />
                    <small>
                        <Trans
                            i18nKey="login:disclaimer"
                            components={[<a href={'https://www.airbnb.com/help/article/3267'} target={'_blank'} />]}
                        />
                    </small>
                </LoginBox>
            </LoginWrapper>
        </Container>
    )
}

Login.displayName = 'Login'
