import {FC} from 'react'
import {FooterWrapper} from '@components/commons/Footer/style.ts'
import {useTranslation} from 'react-i18next'

export const Footer: FC = () => {
    const {t} = useTranslation()

    return (
        <FooterWrapper>
            <a href={'https://www.airbnb.com/help/article/1128'} target={'_blank'}>
                {t('commons:terms_and_conditions')}
            </a>
            <a href={'https://www.airbnb.com/help/article/2855/'} target={'_blank'}>
                {t('commons:privacy_policy')}
            </a>
            {/*<Link to={routes.COOKIE_POLICY.path}> Cookie Policy </Link>*/}
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                Cookie Settings
            </button>
        </FooterWrapper>
    )
}
