import styled, {css} from 'styled-components'

export const LoginWrapper = styled.div`
    ${() => css`
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-rows: 1fr;
        justify-items: center;
        height: 100%;
    `}
`

export const LoginBox = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 524px;

        & img {
            margin: 0 auto ${spacing * 6}px;
        }

        & h4 {
            ${typography.displaySm};
            font-weight: 700;
            color: ${palette.neutral['900']};
        }

        & p {
            ${typography.textMd};
            margin: ${spacing * 2}px 0 ${spacing * 6}px 0;
            color: ${palette.neutral['900']};
        }

        & small {
            ${typography.textSm};
            color: ${palette.neutral['700']};
            margin: ${spacing * 6}px 0 0 0;

            & a {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    `}
`
