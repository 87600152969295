import axios from './axiosInstance.ts'

type AirbnbSSOResponse = {
    oauth2_authorization: {
        access_token: string
        refresh_token: string
        user_id: string
    }
}

export const httpAirbnbSSOLogin = (code: string) => {
    //return axios.get<LoginResponse>(`/co-detector/login?code=${code}`)
    const res = axios.post<AirbnbSSOResponse>(`/co-detector/login`, {
        code: code
    })
    console.log(res)
    return res
}

export const httpLogout = () => {
    return axios.get('/airbnb/logout')
}

export const httpUserMe = () => {
    return axios.get('/users/me')
}
