import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledModalToggle} from '@components/commons/ChangeLangModalTrigger/style.ts'
import {APP_LANGUAGES} from '@/translations/i18n.ts'
import {Globe02Icon} from 'src/components/ui/icon'

export const ChangeLangModalTrigger: FC<{showCallback?: () => void}> = ({showCallback}) => {
    const {
        i18n: {language}
    } = useTranslation()

    return (
        <>
            <StyledModalToggle onClick={showCallback}>
                <Globe02Icon width={20} />
                {APP_LANGUAGES.filter(lang => lang.value === language)[0].label}
            </StyledModalToggle>
        </>
    )
}

ChangeLangModalTrigger.displayName = 'ChangeLangModalTrigger'
