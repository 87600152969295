import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Modal} from '@/components/ui/modal/Modal'
import styled, {css} from 'styled-components'

export const StyledFormContainer = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        max-width: 500px;

        form {
            display: grid;
            grid-template-columns: 1fr;
            margin-top: ${spacing * 10}px;
            gap: ${spacing * 4}px;
        }

        & > small {
            ${typography.textSm};
            color: ${palette.neutral['600']};
            display: grid;
            gap: ${spacing * 4}px;
            padding-bottom: ${spacing * 10}px;
            & > span > a {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    `}
`

export const FormSectionInfo = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        & h4 {
            ${typography.textLg};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }

        & p {
            ${typography.textMd};
            color: ${palette.neutral['700']};
            display: flex;
            align-items: center;
            gap: ${spacing * 2}px;
            & button {
                height: 18px;
            }
        }
    `}
`

export const StyledFlexWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 4}px;
        ${mediaQueries.m} {
            grid-template-columns: 1fr;
        }
    `}
`

export const PhoneInputWrapper = styled.div`
    ${({theme: {spacing, palette, typography, shadows}}) => css`
        display: flex;
        flex-direction: column;
        gap: 6px;

        .react-tel-input > input {
            ${typography.textMd}
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${spacing}px;
            border: 1px solid ${palette.neutral['300']};
            width: 100%;
            transition: ease-in-out 200ms;
            cursor: pointer;
            height: 44px;
            padding: 0 ${spacing * 9}px 0 ${spacing * 12}px;
            &:not(:disabled):hover {
                border: 1px solid ${palette.primary['200']};
                box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.primary['25']}`};
            }
            &:focus {
                outline: none;
                border-color: ${palette.neutral['300']};
                box-shadow: unset;
            }
            &::placeholder {
                color: ${palette.neutral['400']};
            }
            &:disabled {
                cursor: not-allowed;
            }
        }
    `}
`

export const GooglePlacesAutocompleteWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.5}px;
    `}
`

export const StyledFormattedAddress = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        & strong {
            ${typography.textSm};
            margin-right: ${spacing * 2}px;
            font-weight: 500;
            color: ${palette.neutral['700']};
        }
        & span {
            ${typography.textSm};
            color: ${palette.neutral['600']};
        }
    `}
`

export const SubmitBtnWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        width: 100px;
        margin: 0 0 ${spacing * 12}px;
    `}
`
export const StyledManualAddressFormHeader = styled(Flexbox)(
    ({theme: {typography, palette, spacing}}) => css`
        padding: ${spacing * 6}px;
        color: ${palette.neutral[900]};

        h3 {
            ${typography.textXl};
            font-weight: 500;
        }
    `
)

export const StyledManualAddressFormContainer = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        padding: 0 ${spacing * 6}px ${spacing * 6}px ${spacing * 6}px;
        overflow: auto;
        height: 100%;
    `}
`

export const StyledManualAddressLocationWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: ${spacing * 2}px;
    `}
`

export const StyledCoAlarmRequestModal = styled(Modal)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
    max-height: 780px;
`

export const StyledCoManualAddressFormFooter = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral[300]};
    `
)
