import Container from '@components/ui/Container'
import {HeaderWrapper, HeaderGrid, MenuItems, DropdownUserInfosItems} from '@components/commons/Header/style.ts'
import Avatar from '@components/ui/Avatar'
import LogoDark from '@assets/images/logo_dark.svg'
import NavLink from '@components/ui/NavLink'
import {ChangeLangModalTrigger} from 'src/components/commons/ChangeLangModalTrigger'
import DropdownMenu from '@components/ui/DropdownMenu'
import {useTranslation} from 'react-i18next'
import {AirbnbIcon, LogoutIcon} from 'src/components/ui/icon'
import {useAuth} from '@context/AuthProvider.tsx'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {useMemo, useState} from 'react'
import {LangSelectionModal} from '@components/commons/lang-selection-modal/LangSelectionModal.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {envVars} from '@/envVars.ts'

export type MenuItem = {
    name: string
    path: string
}

interface HeaderProps {
    items: MenuItem[]
}

const Header = ({items}: HeaderProps) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 768, [width])
    const {logout, user} = useAuth()
    const [showLangModal, setShowLangModal] = useState(false)
    const airbnbLoginHandler = () => {
        window.location.href = `https://www.airbnb.com/oauth2/auth?client_id=${envVars.VITE_AIRBNB_APP_ID}&redirect_uri=${envVars.VITE_AIRBNB_DATA_REDIRECT_URI}&scope=${envVars.VITE_AIRBNB_DATA_SCOPE}&state`
    }

    return (
        <HeaderWrapper>
            <Container>
                <HeaderGrid>
                    <img src={LogoDark} alt={'logo'} height={40} />
                    <MenuItems>
                        {items.map(({name, path}) => (
                            <NavLink path={path} name={name} key={name} />
                        ))}
                    </MenuItems>
                    {!isMobile && <ChangeLangModalTrigger showCallback={() => setShowLangModal(!showLangModal)} />}
                    <DropdownMenu
                        sideOffset={8}
                        trigger={<Avatar name={user?.first_name ?? 'User'} imageUrl={user?.picture_url ?? ''} />}
                        actions={[
                            ...(user
                                ? [
                                      {
                                          component: (
                                              <DropdownUserInfosItems>
                                                  <span>{user?.first_name ?? 'User'}</span>
                                                  <small>Airbnb Host</small>
                                              </DropdownUserInfosItems>
                                          ),
                                          withSeparator: true
                                      }
                                  ]
                                : []),
                            ...(!user
                                ? [
                                      {
                                          component: (
                                              <DropdownUserInfosItems onClick={airbnbLoginHandler}>
                                                  <Flexbox justify={'space-between'} align={'center'} gap={2}>
                                                      <AirbnbIcon size={16} />
                                                      <small> {t('login:cta')}</small>
                                                  </Flexbox>
                                              </DropdownUserInfosItems>
                                          ),
                                          withSeparator: true
                                      }
                                  ]
                                : []),
                            ...(isMobile
                                ? [
                                      {
                                          component: <ChangeLangModalTrigger />,
                                          onClickCb: () => setShowLangModal(!showLangModal)
                                      }
                                  ]
                                : []),
                            ...(user
                                ? [
                                      {
                                          component: t('commons:logout'),
                                          icon: <LogoutIcon size={16} />,
                                          onClickCb: logout
                                      }
                                  ]
                                : [])
                        ]}
                    />
                </HeaderGrid>
            </Container>
            {showLangModal && <LangSelectionModal onCloseCb={() => setShowLangModal(false)} />}
        </HeaderWrapper>
    )
}

export default Header
