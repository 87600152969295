import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledPromoBannerWide = styled(Flexbox)(
    ({theme: {palette, spacing, mediaQueries}}) => css`
        color: ${palette.neutral.white};
        position: relative;
        width: 100%;
        border-radius: 16px;
        overflow: hidden;
        height: 182px;
        margin: ${spacing * 10}px 0px;
        padding: ${spacing * 6}px ${spacing * 10}px;

        & > svg {
            z-index: 1;
        }

        & > img {
            position: absolute;
            width: 650px;
            object-fit: cover;
            right: 0;
            z-index: 0;
        }

        ${mediaQueries.l} {
            margin: 0 0 ${spacing * 8}px 0px;
        }

        ${mediaQueries.m} {
            height: auto;
            border-radius: 0;

            & > img {
                right: -50%;
            }
        }
    `
)

export const StyledPromoBannerWideOverlay = styled.div(
    ({theme: {palette, mediaQueries}}) => css`
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background: linear-gradient(90deg, ${palette.neutral.black} 50%, transparent 65%);
        ${mediaQueries.m} {
            background: linear-gradient(90deg, ${palette.neutral.black} 65%, transparent 85%);
        }
    `
)

export const StyledPromoBannerWideContent = styled(Flexbox)(
    ({theme: {mediaQueries}}) => css`
        position: relative;
        ${mediaQueries.m} {
            flex-direction: column;
        }
    `
)

export const StyledPromoBannerWideCopy = styled(Flexbox)(
    ({theme: {typography, mediaQueries, spacing}}) => css`
        flex: 1;
        h3 {
            margin-top: ${spacing * 2}px;
            font-weight: 500;
            ${typography.displayXs};
            max-width: 250px;
        }

        p {
            ${typography.textSm};
            b {
                ${typography.displayXs};
                display: block;
                font-weight: 700;
            }
        }

        ${mediaQueries.m} {
            flex-direction: column;
            gap: ${spacing}px;
            margin-bottom: ${spacing * 4}px;
        }
    `
)
