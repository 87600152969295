import styled, {css, DefaultTheme} from 'styled-components'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

export const AvatarRoot = styled(AvatarPrimitive.Root)`
    ${({theme: {palette}}) => css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        user-select: none;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        background: ${palette.neutral['100']};
        border: 1px ${palette.neutral['300']};
    `}
`
export const AvatarImage = styled(AvatarPrimitive.Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
`
export const AvatarFallback = styled(AvatarPrimitive.Fallback)`
    ${({palette, typography}: DefaultTheme) => css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${palette.neutral.white};
        color: ${palette.neutral['600']};
        ${typography.textMd};
        font-weight: 600;
    `}
`
