import {Trans, useTranslation} from 'react-i18next'

export const SmokeFormFooter = () => {
    const {t} = useTranslation()

    return (
        <small>
            <span>{t('smokeForm:terms_1')}</span>
            <span>
                <Trans
                    i18nKey="smokeForm:terms_2"
                    components={[
                        <a href={'https://www.airbnb.com/help/article/1128'} target={'_blank'} />,
                        <a href={'https://www.airbnb.com/help/article/2855/'} target={'_blank'} />
                    ]}
                />
            </span>
        </small>
    )
}

SmokeFormFooter.displayName = 'SmokeFormFooter'
