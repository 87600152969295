import {z} from 'zod'

export const SMOKE_FORM_MODEL = {
    sensorsRequested: {
        name: 'sensors_requested',
        label: '',
        defaultValue: 1,
        placeholder: '',
        helpText: ''
    },
    firstName: {
        name: 'name',
        label: 'smokeForm:form:first_name',
        defaultValue: '',
        placeholder: 'smokeForm:form:first_name_placeholder',
        helpText: ''
    },
    lastName: {
        name: 'surname',
        label: 'smokeForm:form:last_name',
        defaultValue: '',
        placeholder: 'smokeForm:form:last_name_placeholder',
        helpText: ''
    },
    country: {
        name: 'country',
        label: 'smokeForm:form:country',
        defaultValue: [],
        placeholder: 'smokeForm:form:country_placeholder',
        helpText: ''
    },
    phone: {
        name: 'phone',
        label: 'smokeForm:form:phone',
        defaultValue: '',
        placeholder: 'smokeForm:form:phone_placeholder',
        helpText: ''
    },
    address: {
        name: 'address',
        label: 'smokeForm:form:address',
        defaultValue: [],
        placeholder: 'smokeForm:form:address_placeholder',
        helpText: ''
    },
    addressExtras: {
        name: 'address_extras',
        label: 'smokeForm:form:address_extras',
        defaultValue: '',
        placeholder: '',
        helpText: ''
    }
} as const

export const SmokeFormSchema = z.object({
    sensors_requested: z.number(),
    name: z.string().min(1, {message: 'smokeForm:form:first_name_error'}),
    surname: z.string().min(1, {message: 'smokeForm:form:last_name_error'}),
    address_line_2: z.string().optional(),
    country: z.object({
        label: z.unknown(),
        value: z.string()
    }),
    address: z.object({
        label: z.string(),
        value: z.unknown()
    }),
    manual_address: z.boolean(),
    address_extras: z.string().optional(),
    formatted_address: z.string().optional(),
    phone: z.string().min(12, {message: 'smokeForm:form:phone_error'})
})

export type ValidationSchema = z.infer<typeof SmokeFormSchema>
