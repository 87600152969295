import {createBrowserRouter, Outlet} from 'react-router-dom'
import PrivateRoute from '@route-guards/private-route/PrivateRoute.tsx'
import PublicRoute from '@route-guards/public-route/PublicRoute.tsx'
import PublicLayout from '@layouts/public-layout/PublicLayout.tsx'
import PrivateLayout from '@layouts/private-layout/PrivateLayout.tsx'
import {routes} from '@utilities/constants'

const router = createBrowserRouter(
    [
        {
            ...routes.NOT_FOUND
        },
        {
            element: <PrivateRoute />, //all authenticated user layout route
            children: [
                {
                    element: <PrivateLayout withHeader />,
                    handle: {...routes.HOME.handle},
                    children: [{...routes.HOME}, {...routes.INACTIVE}, {...routes.REQUEST_DETAILS}]
                }
            ]
        },

        {
            element: <PublicRoute />,
            children: [
                {
                    element: <PublicLayout />,
                    children: [{...routes.LOGIN}]
                }
            ]
        },

        {
            element: <PublicRoute />,
            children: [
                {
                    element: <PublicLayout />,
                    children: [{...routes.SSO_REDIRECT}]
                }
            ]
        },

        {
            element: (
                <>
                    <Outlet />
                </>
            ),
            children: [
                {
                    element: <PublicLayout />,
                    children: [{...routes.COOKIE_POLICY}]
                }
            ]
        }
        /*{
            path: '*', // Not found route
            element: <pages.NotFound />,
        },*/
    ],
    {
        basename: '/'
    }
)

export default router
