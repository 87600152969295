import React, {useEffect, useMemo} from 'react'
import Container from '@components/ui/Container'
import {Trans, useTranslation} from 'react-i18next'
import {RequestDetailsBox, RequestDetailsWrapper} from '@pages/requestDetails/style.ts'
import Done from '@assets/images/request_completed_ico.svg'
import {useAuth} from '@context/AuthProvider.tsx'
import Spinner from '@components/ui/Spinner'
import {RequestsDetailsSummary} from '@components/commons/RequestsDetailsSummary/RequestDetailsSummary.tsx'
import {Helmet} from 'react-helmet'
import {PromoBannerWide} from '@components/commons/PromoBannerWide/PromoBannerWide.tsx'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {Camera01Icon} from 'src/components/ui/icon'
import {useTheme} from 'styled-components'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {ServiceSuspendedBanner} from '@components/commons/service-suspended-banner/ServiceSuspendedBanner.tsx'

export const RequestDetails: React.FC = () => {
    const {t} = useTranslation()
    //const navigate = useNavigate()
    const {user, userMe, isLoading} = useAuth()
    const {palette} = useTheme()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width <= 768, [width])

    //TODO: temporary to avoid other requests
    /* const remainingSensorsToRequest = useMemo(() => {
        const activeListings = user?.active_listings || 0
        const sensorsRequested = user?.total_sensors_requested || 0
        return activeListings <= sensorsRequested
            ? 0
            : Math.min(activeListings - sensorsRequested, 3 - sensorsRequested)
    }, [user])*/

    useEffect(() => {
        userMe && userMe()
    }, [])

    return (
        <Container>
            <Helmet title={String(t('requestDetails:page_title'))} />
            <RequestDetailsWrapper>
                <RequestDetailsBox>
                    <img src={Done} alt={'completed'} width={48} />
                    <h4>{t('requestDetails:title')}</h4>
                    {/*TODO: temporary to avoid other requests*/}
                    <ServiceSuspendedBanner />
                    {/* {remainingSensorsToRequest > 0 && !isLoading && (
                        <>
                            <p>{t('requestDetails:still_request', {count: remainingSensorsToRequest})}</p>
                            <Button variant={'primary'} size={'md'} onClick={() => navigate(routes.HOME.path)}>
                                {t('requestDetails:still_request_cta')}
                            </Button>
                        </>
                    )}*/}

                    {isLoading ? (
                        <Spinner size={24} color={'#333'} />
                    ) : (
                        <RequestsDetailsSummary
                            requests={user?.co_sensor_requests_details ?? []}
                            accordionEnabled={false}
                        />
                    )}
                </RequestDetailsBox>
            </RequestDetailsWrapper>

            <PromoBannerWide
                title={t('photo_promo_banner:title')}
                desc={<Trans i18nKey={'photo_promo_banner:subtitle'} components={[<b />]} />}
                cta={
                    <ButtonLink
                        variant="primary"
                        size="lg"
                        fullWidth={isMobile}
                        target={'_blank'}
                        to="https://elevate.translated.com/pro-photography"
                    >
                        {t('photo_promo_banner:cta')}
                    </ButtonLink>
                }
                serviceIcon={<Camera01Icon size={36} fill={palette.neutral.white} />}
            />
        </Container>
    )
}

RequestDetails.displayName = 'RequestDetails'
