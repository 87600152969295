import {FC, useEffect} from 'react'
import Container from '@components/ui/Container'
import {useTranslation} from 'react-i18next'
import Alert from '@assets/images/inactive_ico.svg'
import {InactiveBox, InactiveWrapper} from '@pages/inactive/style.ts'
import {useAuth} from '@context/AuthProvider.tsx'
import {useNavigate} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {Helmet} from 'react-helmet'

export const Inactive: FC = () => {
    const {t} = useTranslation()
    const {user} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (user && user.active_listings !== 0) {
            navigate(routes.HOME.path)
        }
    }, [user])

    return (
        <Container>
            <Helmet title={String(t('inactive:page_title'))} />
            <InactiveWrapper>
                <InactiveBox>
                    <img src={Alert} alt={'inactive'} width={64} />
                    <h4>{t('inactive:title')}</h4>
                    <p>{t('inactive:subtitle')}</p>
                    <a href={'https://www.airbnb.com/manage-your-space'} target={'_blank'}>
                        {t('inactive:cta')}
                    </a>
                    {/* <ServiceSuspendedBanner />*/}
                </InactiveBox>
            </InactiveWrapper>
        </Container>
    )
}

Inactive.displayName = 'Inactive'
