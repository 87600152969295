import {Navigate, Outlet} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {useAuth} from '@context/AuthProvider.tsx'

const PublicRoute = () => {
    const {accessToken} = useAuth()

    return accessToken ? <Navigate to={routes.HOME.path} /> : <Outlet />
}

export default PublicRoute
