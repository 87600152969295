import {useTranslation} from 'react-i18next'
import {StyledInputText, StyledLangModalHeader, StyledLangModalHeaderContent} from './style'
import {SearchLgIcon, XCloseIcon} from '@/components/ui/icon'
import {useTheme} from 'styled-components'
import {FC} from 'react'
import Button from '@components/ui/button/Button'
import {APP_LANGUAGES} from '@/translations/i18n'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {breakpoints} from '@/theme/mediaQueries'

interface LangModalHeaderProps {
    onClose: () => void
    onChangeSearch: (lang: string) => void
}

export const LangSelectionModalHeader: FC<LangModalHeaderProps> = ({onClose, onChangeSearch}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {width: viewportWidth} = useWindowDimensions()

    return (
        <StyledLangModalHeader
            justify={'flex-start'}
            direction={viewportWidth <= breakpoints.width.s ? 'column' : 'row'}
            gap={viewportWidth <= breakpoints.width.m ? 2 : 4}
        >
            <StyledLangModalHeaderContent align={'center'}>
                <Button size="lg" iconOnly variant="ghost" onClick={onClose}>
                    <XCloseIcon />
                </Button>
                <h3>{t('commons:select_lang_title')}</h3>
            </StyledLangModalHeaderContent>

            {APP_LANGUAGES.length > 12 && (
                <StyledInputText
                    inputSize="sm"
                    placeholder={String(t('commons:search_languages'))}
                    type="text"
                    typeIcon={<SearchLgIcon size={20} fill={theme.palette.neutral['500']} />}
                    onChange={event => onChangeSearch(event.target.value)}
                />
            )}
        </StyledLangModalHeader>
    )
}
