import {FC, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {APP_LANGUAGES} from '@/translations/i18n.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox'
import {StyledLangModal, StyledLangModalList} from './style'
import {Modal} from '@components/ui/modal/Modal'
import {LangSelectionModalHeader} from './LangSelectionModalHeader.tsx'

interface LangModalProps {
    onCloseCb: () => void
}

export const LangSelectionModal: FC<LangModalProps> = ({onCloseCb}) => {
    const {
        t,
        i18n,
        i18n: {language}
    } = useTranslation()
    const currentLanguage = APP_LANGUAGES.find(lang => lang.value == language) ?? APP_LANGUAGES[0]
    const [search, setSearch] = useState('')
    const onChangeLanguage = async (nextLanguage: string) => {
        await i18n.changeLanguage(nextLanguage)
    }

    const languagesList = useMemo(() => {
        return [currentLanguage, ...APP_LANGUAGES.filter(lang => lang.value != currentLanguage.value)].filter(
            lang =>
                lang.label.toLowerCase().includes(search.toLowerCase()) ||
                lang.value.toLowerCase().includes(search.toLowerCase())
        )
    }, [currentLanguage, search])

    return (
        <Modal width={850} onOverlayClick={onCloseCb}>
            <StyledLangModal>
                <LangSelectionModalHeader onClose={onCloseCb} onChangeSearch={setSearch} />

                {languagesList.length >= 1 ? (
                    <StyledLangModalList>
                        {languagesList.map(lang => (
                            <button
                                className={lang.value == currentLanguage.value ? 'current-language' : ''}
                                onClick={() => onChangeLanguage(lang.value)}
                                key={lang.value}
                            >
                                {lang.label}
                            </button>
                        ))}
                    </StyledLangModalList>
                ) : (
                    <Flexbox height="100%" width="100%" justify="center" align="center" padding={6}>
                        {t('commons:no_language_results')}
                    </Flexbox>
                )}
            </StyledLangModal>
        </Modal>
    )
}

LangSelectionModal.displayName = 'LangSelectionModal'
