import {FC} from 'react'
import Container from '@components/ui/Container'

export const CookiePolicy: FC = () => {
    return (
        <Container>
            <div id="ot-sdk-cookie-policy">
                <div id="ot-sdk-cookie-policy-v2" className="ot-sdk-cookie-policy ot-sdk-container">
                    <h3 id="cookie-policy-title" className="ot-sdk-cookie-policy-title">
                        Cookie List
                    </h3>
                    <div id="cookie-policy-description">
                        A cookie is a small piece of data (text file) that a website – when visited by a user – asks
                        your browser to store on your device in order to remember information about you, such as your
                        language preference or login information. Those cookies are set by us and called first-party
                        cookies. We also use third-party cookies – which are cookies from a domain different than the
                        domain of the website you are visiting – for our advertising and marketing efforts. More
                        specifically, we use cookies and other tracking technologies for the following purposes:
                    </div>

                    <section>
                        <h4 className="ot-sdk-cookie-policy-group">Strictly Necessary Cookies</h4>
                        <p className="ot-sdk-cookie-policy-group-desc">
                            These cookies are necessary for the website to function and can't be switched off in our
                            systems. They are usually only set in response to actions you have taken which result in a
                            request for services, such as setting your privacy preferences, logging in or filling in
                            forms. You can set your browser to block or alert you about these cookies, but some parts of
                            the site may not work as a result.
                        </p>

                        <table>
                            <caption className="ot-scrn-rdr">Strictly Necessary Cookies</caption>
                            <thead>
                                <tr>
                                    <th scope="col" className="ot-table-header ot-host">
                                        Cookie Subgroup
                                    </th>

                                    <th scope="col" className="ot-table-header ot-cookies">
                                        Cookies
                                    </th>
                                    <th scope="col" className="ot-table-header ot-cookies-type">
                                        Cookies used
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="ot-host-td" data-label="Cookie Subgroup">
                                        <span className="ot-mobile-border"></span>mediabox-co-alarm.translated.com
                                    </td>

                                    <td className="ot-cookies-td" data-label="Cookies">
                                        <span className="ot-mobile-border"></span>
                                        <span className="ot-cookies-td-content">
                                            <a
                                                href="https://cookiepedia.co.uk/cookies/OptanonAlertBoxClosed"
                                                rel="noopener"
                                                target="_blank"
                                                aria-label="OptanonAlertBoxClosed Opens in a new Tab"
                                            >
                                                OptanonAlertBoxClosed
                                            </a>
                                            ,
                                            <a
                                                href="https://cookiepedia.co.uk/cookies/OptanonConsent"
                                                rel="noopener"
                                                target="_blank"
                                                aria-label="OptanonConsent Opens in a new Tab"
                                            >
                                                OptanonConsent
                                            </a>
                                        </span>
                                    </td>
                                    <td className="ot-cookies-type" data-label="Cookies used">
                                        <span className="ot-mobile-border"></span>
                                        <span className="ot-cookies-type-td-content">First Party</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section>
                        <h4 className="ot-sdk-cookie-policy-group">Performance Cookies</h4>
                        <p className="ot-sdk-cookie-policy-group-desc">
                            These cookies allow us to count visits and traffic sources so we can measure and improve the
                            performance of our site. They help us know which pages are the most and least popular and
                            see how visitors move around the site. If you do not allow these cookies, information from
                            these cookies will not be used to help site performance.
                        </p>

                        <table>
                            <caption className="ot-scrn-rdr">Performance Cookies</caption>
                            <thead>
                                <tr>
                                    <th scope="col" className="ot-table-header ot-host">
                                        Cookie Subgroup
                                    </th>

                                    <th scope="col" className="ot-table-header ot-cookies">
                                        Cookies
                                    </th>
                                    <th scope="col" className="ot-table-header ot-cookies-type">
                                        Cookies used
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="ot-host-td" data-label="Cookie Subgroup">
                                        <span className="ot-mobile-border"></span>translated.com
                                    </td>

                                    <td className="ot-cookies-td" data-label="Cookies">
                                        <span className="ot-mobile-border"></span>
                                        <span className="ot-cookies-td-content">
                                            <a
                                                href="https://cookiepedia.co.uk/cookies/_ga"
                                                rel="noopener"
                                                target="_blank"
                                                aria-label="_ga Opens in a new Tab"
                                            >
                                                _ga
                                            </a>
                                        </span>
                                    </td>
                                    <td className="ot-cookies-type" data-label="Cookies used">
                                        <span className="ot-mobile-border"></span>
                                        <span className="ot-cookies-type-td-content">First Party</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section>
                        <h4 className="ot-sdk-cookie-policy-group">Airbnb</h4>
                        <p className="ot-sdk-cookie-policy-group-desc">Strictly necessary cookies from Airbnb.</p>

                        <table>
                            <caption className="ot-scrn-rdr">Airbnb</caption>
                            <thead>
                                <tr>
                                    <th scope="col" className="ot-table-header ot-host">
                                        Cookie Subgroup
                                    </th>

                                    <th scope="col" className="ot-table-header ot-cookies">
                                        Cookies
                                    </th>
                                    <th scope="col" className="ot-table-header ot-cookies-type">
                                        Cookies used
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="ot-host-td" data-label="Cookie Subgroup">
                                        <span className="ot-mobile-border"></span>
                                        <a
                                            href="https://cookiepedia.co.uk/host/airbnb.ie"
                                            rel="noopener"
                                            target="_blank"
                                            aria-label="airbnb.ie Opens in a new Tab"
                                        >
                                            airbnb.ie
                                        </a>
                                    </td>

                                    <td className="ot-cookies-td" data-label="Cookies">
                                        <span className="ot-mobile-border"></span>
                                        <span className="ot-cookies-td-content">bev</span>
                                    </td>
                                    <td className="ot-cookies-type" data-label="Cookies used">
                                        <span className="ot-mobile-border"></span>
                                        <span className="ot-cookies-type-td-content">Third Party</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ot-host-td" data-label="Cookie Subgroup">
                                        <span className="ot-mobile-border"></span>
                                        <a
                                            href="https://cookiepedia.co.uk/host/airbnb.com"
                                            rel="noopener"
                                            target="_blank"
                                            aria-label="airbnb.com Opens in a new Tab"
                                        >
                                            airbnb.com
                                        </a>
                                    </td>

                                    <td className="ot-cookies-td" data-label="Cookies">
                                        <span className="ot-mobile-border"></span>
                                        <span className="ot-cookies-td-content">
                                            country, cdn_exp_5577d10ed9a3b89e4, ak_bmsc, everest_cookie,
                                            cdn_exp_c381d694c46b4fd03, bev, cdn_exp_a9d6070a0416b7ace
                                        </span>
                                    </td>
                                    <td className="ot-cookies-type" data-label="Cookies used">
                                        <span className="ot-mobile-border"></span>
                                        <span className="ot-cookies-type-td-content">Third Party</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </div>
            </div>
        </Container>
    )
}
