import {FC, ReactElement, ReactNode} from 'react'
import {
    StyledPromoBannerWide,
    StyledPromoBannerWideContent,
    StyledPromoBannerWideCopy,
    StyledPromoBannerWideOverlay
} from '@components/commons/PromoBannerWide/style.ts'
import photoshootBannerBg from '@assets/images/photoshoot_banner_bg.jpg'

interface PromoBannerWideProps {
    bannerBg?: string
    serviceIcon: ReactElement
    title: string
    desc?: ReactNode
    cta: ReactElement
}

export const PromoBannerWide: FC<PromoBannerWideProps> = ({bannerBg, serviceIcon, title, desc, cta}) => {
    return (
        <>
            <StyledPromoBannerWide direction="column" justify="center" gap={2}>
                <img src={bannerBg ?? photoshootBannerBg} alt={'background'} />
                <StyledPromoBannerWideOverlay />
                {serviceIcon}
                <StyledPromoBannerWideContent width={'100%'} align="center">
                    <StyledPromoBannerWideCopy gap={10} width={'100%'}>
                        <h3>{title}</h3>
                        {desc && <p>{desc}</p>}
                    </StyledPromoBannerWideCopy>
                    {cta}
                </StyledPromoBannerWideContent>
            </StyledPromoBannerWide>
        </>
    )
}
