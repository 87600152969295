import Container from '@components/ui/Container'
import PageHeader from '@components/commons/PageHeader'
import {Trans, useTranslation} from 'react-i18next'
import {SmokeForm} from '@pages/home/components/SmokeForm.tsx'
import {StyledSmokeFormWrapper} from '@pages/home/style.ts'
import {Helmet} from 'react-helmet'
import {PromoBannerWide} from '@components/commons/PromoBannerWide/PromoBannerWide.tsx'
import {Camera01Icon} from 'src/components/ui/icon'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {useTheme} from 'styled-components'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {Fragment, useMemo} from 'react'

export const Home = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width <= 768, [width])
    //const {user} = useAuth()

    return (
        <Fragment>
            <Container>
                <Helmet title={String(t('smokeForm:page_title'))} />
                <PageHeader title={t('smokeForm:title')} />
                <StyledSmokeFormWrapper>
                    <SmokeForm />
                    {/* {user && user.co_sensor_requests_details.length > 0 && (
                    <RequestsDetailsSummary requests={user.co_sensor_requests_details} accordionEnabled={true} />
                )}*/}
                </StyledSmokeFormWrapper>
            </Container>

            <Container>
                <PromoBannerWide
                    title={t('photo_promo_banner:title')}
                    desc={<Trans i18nKey={'photo_promo_banner:subtitle'} components={[<b />]} />}
                    cta={
                        <ButtonLink
                            variant="primary"
                            size="lg"
                            fullWidth={isMobile}
                            target={'_blank'}
                            to="https://elevate.translated.com/pro-photography"
                        >
                            {t('photo_promo_banner:cta')}
                        </ButtonLink>
                    }
                    serviceIcon={<Camera01Icon size={36} fill={palette.neutral.white} />}
                />
            </Container>
        </Fragment>
    )
}

Home.displayName = 'Home'
