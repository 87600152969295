import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
    RequestDetailCard,
    RequestDetailCardBody,
    RequestDetailCardHeader,
    ShippingInfoBox
} from '@pages/requestDetails/style.ts'
import {ChevronDownIcon, ClockIcon, Hash01Icon, MarkerPin01Icon} from 'src/components/ui/icon'
import {formatLocaleDate} from '@utilities/helpers.ts'
import {SensorRequest} from '@/types/auth.ts'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'

export const RequestsDetailsSummary: React.FC<{requests: SensorRequest[]; accordionEnabled: boolean}> = ({
    requests,
    accordionEnabled = true
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const [expanded, setExpanded] = useState(width > 768 || !accordionEnabled)

    return (
        <RequestDetailCard>
            <RequestDetailCardHeader expanded={expanded}>
                <p> {t('requestDetails:previous')} </p>
                {accordionEnabled && <ChevronDownIcon onClick={() => setExpanded(!expanded)} />}
            </RequestDetailCardHeader>
            {requests.map((request, index) => (
                <RequestDetailCardBody key={index} expanded={expanded}>
                    <h4>
                        {t('requestDetails:order_sent_on', {date: formatLocaleDate(request.created_at, 'MMM D, YYYY')})}
                    </h4>
                    <ShippingInfoBox>
                        <Hash01Icon width={20} />
                        <div>
                            <small>{t('requestDetails:alarm_count')}</small>
                            <p>{request.sensors_requested}</p>
                        </div>
                    </ShippingInfoBox>
                    <ShippingInfoBox>
                        <MarkerPin01Icon width={20} />
                        <div>
                            <small>{t('requestDetails:shipping_to')}</small>
                            <p>{request.address}</p>
                            <span>{request.address_extras}</span>
                        </div>
                    </ShippingInfoBox>
                    {/* <AddressErrorCta>
                        {t('requestDetails:address_error_cta')}
                        <a href={'mailto:francesco.monti@kromin.it?subject=CO-detector-form-support'}>
                            {t('common:click_here')}
                        </a>
                    </AddressErrorCta>*/}
                    <ShippingInfoBox>
                        <ClockIcon width={20} />
                        <div>
                            <small>{t('requestDetails:delivered_by')}</small>
                            <p>2-3 {t('commons:weeks')}</p>
                        </div>
                    </ShippingInfoBox>
                </RequestDetailCardBody>
            ))}
        </RequestDetailCard>
    )
}

RequestsDetailsSummary.displayName = 'RequestsDetailsSummary'
