import {ValidationSchema} from './ManualAddressFormModel'
import {AddressType, Country, ParsedManualAddress, SantizedFormData} from './types'

const addressTypeReference: Record<keyof SantizedFormData, AddressType> = {
    address_line_1: AddressType.route,
    address_line_optional: AddressType.sublocality_level_1,
    city: AddressType.locality,
    state: AddressType.administrative_area_level_1,
    postal_code: AddressType.postal_code,
    street_number: AddressType.street_number
}

function sanitizeData(data: SantizedFormData): SantizedFormData {
    const obj = {...data}

    for (const key in obj) {
        if (!obj[key as keyof SantizedFormData]) {
            delete obj[key as keyof SantizedFormData]
        }
    }

    return obj
}

export const parseManualAddressForm = (formData: ValidationSchema, country: Country): ParsedManualAddress => {
    const sanitizedFormData: SantizedFormData = sanitizeData({
        address_line_1: formData.address_line_1,
        address_line_optional: [formData.address_line_2, formData.address_line_3, formData.address_line_4]
            .filter(Boolean)
            .join(','),
        city: formData.city,
        state: formData.state,
        postal_code: formData.postal_code,
        street_number: formData.street_number
    })

    return {
        formatted_address: `${sanitizedFormData.address_line_1}, ${sanitizedFormData.street_number}, ${sanitizedFormData.city}, ${sanitizedFormData.postal_code}, ${sanitizedFormData.state}, ${country.value}`,
        address: (Object.keys(sanitizedFormData) as Array<keyof SantizedFormData>)
            .map(addressKey => ({
                long_name: sanitizedFormData[addressKey] as string,
                short_name: sanitizedFormData[addressKey] as string,
                types: [addressTypeReference[addressKey]]
            }))
            .concat({
                long_name: country.value as string,
                short_name: country.code?.toUpperCase() as string,
                types: [AddressType.country]
            })
    }
}
