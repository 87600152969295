import {useEffect, useRef, useState} from 'react'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import Button from '@components/ui/button/Button.tsx'
import {SMOKE_FORM_MODEL, SmokeFormSchema, ValidationSchema} from '@pages/home/components/SmokeFormModel.ts'
import {ValidationSchema as ManualFormValidationSchema} from './ManualAddressFormModel'
import {useTranslation} from 'react-i18next'
import InputSelect, {SelectValue} from '@components/commons/Select'
import {
    FormSectionInfo,
    GooglePlacesAutocompleteWrapper,
    PhoneInputWrapper,
    StyledCoAlarmRequestModal,
    StyledFlexWrapper,
    StyledFormattedAddress,
    StyledFormContainer,
    SubmitBtnWrapper
} from '@pages/home/components/style.ts'
import {envVars} from '@/envVars.ts'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import InputText from '@components/commons/input-text/InputText.tsx'
import {customSelectStyles} from '@components/commons/Select/style.ts'
import {useTheme} from 'styled-components'
import InputHelpText from '@components/ui/InputHelpText'
import Label from '@components/ui/Label'
import {customComponents} from '@components/commons/Select/commons.tsx'
import {Option} from 'react-google-places-autocomplete/build/types'
import {Map} from '@components/commons/Map'
import {useNavigate} from 'react-router-dom'
import {httpSmokeFormSubmit} from '@services/smoke.http.ts'
import {useAuth} from '@context/AuthProvider.tsx'
import Spinner from '@components/ui/Spinner'
import toast from 'react-hot-toast'
import Divider from '@components/ui/Divider'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import {SmokeFormFooter} from '@pages/home/components/SmokeFormFooter.tsx'
import useAddressGeocoding from '@hooks/useAddressGeocoding.ts'
import {debounce} from '@/utilities/helpers'
import {SelectInstance} from 'react-select'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ManualAddressForm} from './ManualAddressForm'
import {parseManualAddressForm} from './utils'
import {Country, ParsedManualAddress} from './types'
import {routes} from '@/utilities/constants'

export const SmokeForm = () => {
    const [emptyPredictionsCount, setEmptyPredictionsCount] = useState(0)
    const [manualAddressModal, setManualAddressModal] = useState(false)
    const [manualAddress, setManualAddress] = useState<ParsedManualAddress | null>(null)
    const theme = useTheme()
    const navigate = useNavigate()
    const {user} = useAuth()
    const inputAddressRef = useRef<SelectInstance<Option | null>>(null)
    const {
        t,
        i18n: {language}
    } = useTranslation()
    //hook form setup
    const {
        control,
        register,
        handleSubmit,
        watch,
        setValue,
        setError,
        resetField,
        // getValues,
        //formState: {errors, isSubmitting, isValid}
        formState: {errors, isSubmitting}
    } = useForm<ValidationSchema>({
        mode: 'onBlur',
        shouldFocusError: true,
        resolver: zodResolver(SmokeFormSchema),
        defaultValues: {
            sensors_requested: 1,
            manual_address: false
        }
    })
    //Geocoder's logic and utils
    const {
        addressLatLng,
        shippingAddressFormat,
        countriesLocalizedOptions,
        retrieveLatLng,
        retrieveStructured,
        resetGeocoding
    } = useAddressGeocoding(language)

    // Watch form changes
    const isManualAddress = watch('manual_address')
    const countrySetted: Country = watch('country')
    const addressSetted = watch('address')

    // Reset fields
    const onResetLocationFields = () => {
        // remove formatted address
        resetGeocoding()
        setEmptyPredictionsCount(0)
        setManualAddress(null)
        resetField(SMOKE_FORM_MODEL.phone.name)
        resetField(SMOKE_FORM_MODEL.address.name, {
            defaultValue: {label: '', value: ''}
        })
    }

    // Handles the input change event for the input address field
    const onInputAddressChange = (inputValue: string) => {
        if (inputValue) {
            const options = inputAddressRef.current?.props.options

            if (manualAddress) {
                setManualAddress(null)
                setValue('manual_address', false)
            }

            if (options && options.length > 0) {
                // If options are available, reset empty predictions count
                setEmptyPredictionsCount(0)
            } else {
                // If no options available, increment empty predictions count up to 2
                setEmptyPredictionsCount(prev => Math.min(prev + 1, 2))
            }
        }
    }

    // Function to handle setting manual address
    const onSetManualAddress = (manualFormValues: ManualFormValidationSchema) => {
        // Reset the count of empty predictions
        setEmptyPredictionsCount(0)
        setValue('manual_address', true)

        // Format the manual address
        const formattedManualAddress = parseManualAddressForm(manualFormValues, countrySetted)

        setManualAddress(formattedManualAddress)
        setValue(
            'address',
            {
                label: formattedManualAddress.formatted_address,
                value: formattedManualAddress.address
            },
            // Validate the field after setting the value
            {shouldValidate: true}
        )
    }

    const onSubmit: SubmitHandler<ValidationSchema> = async data => {
        const address =
            isManualAddress && manualAddress
                ? {
                      address: manualAddress.address,
                      formatted_address: manualAddress.formatted_address
                  }
                : {
                      address: shippingAddressFormat?.address_components,
                      formatted_address: shippingAddressFormat?.formatted_address
                  }

        try {
            console.log({
                ...data,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...(address as google.maps.GeocoderResult),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                country: data.country.label.key,
                id: String(user?.id)
            })

            await httpSmokeFormSubmit({
                ...data,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...(address as google.maps.GeocoderResult),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                country: data.country.label.key,
                id: String(user?.id)
            })

            navigate(routes.REQUEST_DETAILS.path)
        } catch (e) {
            console.error(e)
            toast.error(t('smokeForm:address_info_error'), {
                position: 'top-right'
            })

            setManualAddressModal(true)
            setError('address', {message: String(t('smokeForm:form:address_error'))})
        }
    }

    /*const remainingSensorsToRequest = useMemo(() => {
        const activeListings = user?.active_listings || 0
        const sensorsRequested = user?.total_sensors_requested || 0
        return activeListings <= sensorsRequested
            ? 0
            : Math.min(activeListings - sensorsRequested, 3 - sensorsRequested)
    }, [user])*/

    useEffect(() => {
        if (emptyPredictionsCount === 2) {
            inputAddressRef.current?.blur()
            setManualAddressModal(true)
        }
    }, [emptyPredictionsCount])

    return (
        <StyledFormContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*<FormSectionInfo>
                    <h4>{t('smokeForm:how_many_alarms_title')}</h4>
                    {user && (
                        <p>
                            {t('smokeForm:how_many_alarms_message', {count: remainingSensorsToRequest})}
                            <Tooltip
                                triggerProps={{type: 'button'}}
                                trigger={<HelpCircleIcon size={18} />}
                                content={
                                    <span style={{maxWidth: 300}}>
                                        <Trans i18nKey="smokeForm:tooltip_info" components={[<br />]} />
                                    </span>
                                }
                            />
                        </p>
                    )}
                </FormSectionInfo>*/}
                {/* <IncrementalInputNumber
                    onChangeCb={newValue => setValue(SMOKE_FORM_MODEL.sensorsRequested.name, newValue)}
                    name={SMOKE_FORM_MODEL.sensorsRequested.name}
                    upperLimit={remainingSensorsToRequest}
                    initialValue={1}
                />*/}

                <Divider background={'transparent'} topSpacing={0} bottomSpacing={8} />

                <FormSectionInfo>
                    <h4>{t('smokeForm:shipping_info')}</h4>
                </FormSectionInfo>
                <StyledFlexWrapper>
                    <InputText
                        label={t(SMOKE_FORM_MODEL.firstName.label)}
                        type={'text'}
                        errorMessage={t(errors.name?.message || '')}
                        placeholder={t(SMOKE_FORM_MODEL.firstName.placeholder).toString()}
                        {...register(t(SMOKE_FORM_MODEL.firstName.name))}
                        helpText={t(SMOKE_FORM_MODEL.firstName.helpText)}
                    />
                    <InputText
                        label={t(SMOKE_FORM_MODEL.lastName.label)}
                        type={'text'}
                        errorMessage={t(errors.surname?.message || '')}
                        placeholder={t(SMOKE_FORM_MODEL.lastName.placeholder).toString()}
                        {...register(t(SMOKE_FORM_MODEL.lastName.name))}
                        helpText={t(SMOKE_FORM_MODEL.lastName.helpText)}
                    />
                </StyledFlexWrapper>

                <Controller
                    render={({field: {onChange, value}}) => (
                        <InputSelect
                            key={`lang-${language}`}
                            value={value as SelectValue}
                            onChange={newValue => {
                                onChange(newValue as SelectValue)
                                onResetLocationFields()
                            }}
                            size={'medium'}
                            name={SMOKE_FORM_MODEL.country.name}
                            label={t(SMOKE_FORM_MODEL.country.label)}
                            isSearchable={true}
                            errorMessage={t(errors.country?.message || '')}
                            placeholder={t(SMOKE_FORM_MODEL.country.placeholder)}
                            options={countriesLocalizedOptions}
                        />
                    )}
                    control={control}
                    name={'country'}
                />

                {countrySetted && (
                    <>
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <GooglePlacesAutocompleteWrapper>
                                    <Label htmlFor={SMOKE_FORM_MODEL.address.name}>
                                        {t(SMOKE_FORM_MODEL.address.label)}
                                    </Label>
                                    <GooglePlacesAutocomplete
                                        selectProps={{
                                            ref: inputAddressRef,
                                            isDisabled: !countrySetted?.code,
                                            placeholder: t(SMOKE_FORM_MODEL.address.placeholder),
                                            name: SMOKE_FORM_MODEL.address.name,
                                            onInputChange: debounce(onInputAddressChange, 750),
                                            isClearable: true,
                                            value: value as Option,
                                            onChange: newValue => {
                                                onChange(newValue as SelectValue)
                                                newValue?.label && retrieveLatLng(newValue.label)
                                                newValue?.value && retrieveStructured(newValue.value.place_id)
                                            },
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            //@ts-ignore
                                            components: {...customComponents, DropdownIndicator: () => null},
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            //@ts-ignore
                                            styles: customSelectStyles({theme, isError: !!errors.address?.message})
                                        }}
                                        autocompletionRequest={{
                                            //types: ['address','place'],
                                            componentRestrictions: {
                                                country: countrySetted?.code ?? ['me', 'br']
                                            }
                                        }}
                                        apiOptions={{language: language, region: countrySetted?.code}}
                                        apiKey={envVars.VITE_GOOGLE_API_KEY}
                                    />
                                    <InputHelpText error={errors.address?.message} />

                                    {emptyPredictionsCount === 2 && (
                                        <Flexbox justify="end">
                                            <Button
                                                variant={'ghost'}
                                                size={'sm'}
                                                onClick={() => setManualAddressModal(true)}
                                            >
                                                {t('smokeForm:form:manual_hint')}
                                            </Button>
                                        </Flexbox>
                                    )}

                                    {(shippingAddressFormat || manualAddress) && addressSetted && (
                                        <StyledFormattedAddress>
                                            <strong>{t('smokeForm:form:formatted_address')}</strong>
                                            <span>
                                                {!isManualAddress
                                                    ? shippingAddressFormat?.formatted_address
                                                    : manualAddress?.formatted_address}
                                            </span>
                                        </StyledFormattedAddress>
                                    )}
                                </GooglePlacesAutocompleteWrapper>
                            )}
                            control={control}
                            name={'address'}
                        />

                        <InputText
                            type="text"
                            label={t(SMOKE_FORM_MODEL.addressExtras.label)}
                            errorMessage={t(errors.address_extras?.message ?? '')}
                            {...register(SMOKE_FORM_MODEL.addressExtras.name)}
                        />

                        <Controller
                            render={({field: {onChange, value}}) => (
                                <PhoneInputWrapper>
                                    <Label htmlFor={SMOKE_FORM_MODEL.phone.name}>
                                        {t(SMOKE_FORM_MODEL.phone.label)}
                                    </Label>
                                    <PhoneInput
                                        country={countrySetted?.code ?? 'us'}
                                        placeholder="Enter phone number"
                                        value={value}
                                        onChange={onChange}
                                    />
                                    <InputHelpText error={t(errors.phone?.message || '')} />
                                </PhoneInputWrapper>
                            )}
                            control={control}
                            name={'phone'}
                        />

                        {addressLatLng && !isManualAddress && addressSetted && (
                            <Map center={addressLatLng} markerPosition={addressLatLng} />
                        )}
                    </>
                )}

                <SubmitBtnWrapper>
                    <Button
                        type={'submit'}
                        variant={'primary'}
                        size={'md'}
                        //disabled={!isValid || isSubmitting || !addressSetted?.value}
                        disabled={true}
                    >
                        {isSubmitting ? <Spinner size={14} color={theme.palette.neutral.white} /> : t('commons:submit')}
                    </Button>
                </SubmitBtnWrapper>
            </form>

            <SmokeFormFooter />

            {manualAddressModal && (
                <StyledCoAlarmRequestModal width={540} onOverlayClick={() => setManualAddressModal(false)}>
                    <ManualAddressForm
                        closeModal={() => setManualAddressModal(false)}
                        onSetManualAddress={onSetManualAddress}
                    />
                </StyledCoAlarmRequestModal>
            )}
        </StyledFormContainer>
    )
}

SmokeForm.displayName = 'HomeForm'
