import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './translations/i18n.ts'
import {QueryClientProvider} from '@tanstack/react-query'
import {queryClient} from '@/queryClient.ts'
import {Helmet} from 'react-helmet'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Helmet>
                <script src="https://www.airbnb.com/platform_js" async defer></script>
            </Helmet>
            <App />
        </QueryClientProvider>
    </React.StrictMode>
)
