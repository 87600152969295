import axios from './axiosInstance.ts'
import {ValidationSchema} from '@pages/home/components/SmokeFormModel.ts'

type SmokeFormSubmitParams = ValidationSchema & {
    id: string
    sensors_requested: number
}

export const httpSmokeFormSubmit = (data: SmokeFormSubmitParams) => {
    return axios.post('/smoke-request', data)
}
